
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { FinalRelationshipProvider, UserProvider, PriorityPartsProvider, ReportPartsProvider, RelationshipProvider } from "./context/Context"

import ReportParts from "./page/ReportParts/Application";
import PriorityParts from "./page/PriorityParts/Application";
import FinalRelationship from "./page/FinalRelationship/Application";
import User from "./page/User/Application";
import Relationship from "./page/Relationship/Application";

import Home from "./page/Home";


function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/ReportParts" element={
            <ReportPartsProvider>
              <ReportParts />
            </ReportPartsProvider>
          } exact />

          {/* <Route path="/Planning" element={
            <PlanningProvider>
              <Planning />
            </PlanningProvider>
          } exact /> */}

          <Route path="/FinalRelationship" element={
            <FinalRelationshipProvider>
              <FinalRelationship />
            </FinalRelationshipProvider>
          } exact />

          <Route path="/PriorityParts" element={
            <PriorityPartsProvider>
              <PriorityParts />
            </PriorityPartsProvider>
          } exact />

          <Route path="/Relationship" element={
            <RelationshipProvider>
              <Relationship />
            </RelationshipProvider>
          } exact />


          <Route path="/User" element={<User />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </UserProvider>
    </Router>
  );
}
export default App;
