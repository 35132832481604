import { useContext, useEffect, useRef, useState } from "react";
import { PriorityPartsContext } from "../../../context/Context";

export default function SelectDriver() {
    const { setSelectedKey, objData } = useContext(PriorityPartsContext);

    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const optionClickedRef = useRef(false);

    // Atualiza as opções com base no novo formato de dados
    useEffect(() => {
        const formattedOptions = Object.values(objData).map((route) => ({
            key: route.router, // Agora a key é a router
            label: `${route.router} |  ${route.total}`, // Label é apenas a router
        }));

        setOptions(formattedOptions);
        setFilteredOptions(formattedOptions);
        setInputValue('');
        setSelectedKey('');
    }, [objData, setSelectedKey]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        if (value === '') {
            setSelectedKey('');
        }

        const filtered = options.filter(
            (option) =>
                option.label.toLowerCase().includes(value.toLowerCase()) ||
                option.key.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
        setIsDropdownOpen(true);
    };

    const handleOptionClick = (option) => {
        optionClickedRef.current = true;
        setInputValue(option.label);
        setSelectedKey(option.key);
        setIsDropdownOpen(false);
    };

    const handleFocus = () => {
        setFilteredOptions(options);
        setIsDropdownOpen(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!optionClickedRef.current) {
                const isInputValid = options.some(
                    (option) => option.label === inputValue || option.key === inputValue
                );
                if (!isInputValid) {
                    setInputValue('');
                    setSelectedKey('');
                }
            }
            optionClickedRef.current = false;
            setIsDropdownOpen(false);
        }, 200);
    };

    return (
        <div className="custom-select">
            <input
                type="text"
                placeholder="Escolher"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="custom-input"
            />
            {isDropdownOpen && (
                <ul className="dropdown">
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option) => (
                            <li
                                key={option.key}
                                onClick={() => handleOptionClick(option)}
                                className="dropdown-item"
                            >
                                {option.label}
                            </li>
                        ))
                    ) : (
                        <li className="dropdown-item">Nenhuma opção</li>
                    )}
                </ul>
            )}
        </div>
    );
}
