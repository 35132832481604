import Header from '../Header';
import Main from '../Main';
import './styles.scss';

export default function Home(){
    return (
        <div id="PriorityParts">
            <Header />
            <Main />
        </div>
    )
}