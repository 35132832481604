import { FaUser } from 'react-icons/fa';
import './styles.scss';

import UploadFile from './UploadFile';
import { useContext, useEffect } from 'react';
import { PriorityPartsContext } from '../../../context/Context';
import Checked from './Checked';
import ReactInputMask from 'react-input-mask';
import { FaCalendarDays } from 'react-icons/fa6';

export default function Header() {
    const { setName, setProcessedData, dataPriority, setDataPriority, checkedUnwanted, setCheckedUnwanted, checkedMetropolitan, setCheckedMetropolitan, checkedInt, setCheckedInt} = useContext(PriorityPartsContext);

    const handleNameChange = (event) => {
        const value = event.target.value;
        if (/^[A-Za-z\s]*$/.test(value)) {
            setName(value);
        }
    };

    const handleDataPriorityChange = (event) => {
        const value = event.target.value;

        setDataPriority(value);
    };

    return (
        <header id='Header'>
            <div className='configs'>
                <div className="title">
                    <span>Relatório Prioridade</span>
                </div>
                <div className="select">
                    <span>Ocultar Indesejados</span>
                    <Checked checked={checkedUnwanted} setChecked={setCheckedUnwanted} />
                </div>
                <div className="select">
                    <span>Metropolitano</span>
                    <Checked checked={checkedMetropolitan} setChecked={setCheckedMetropolitan} /> 
                </div>
                <div className="select">
                    <span>Interior</span>
                    <Checked checked={checkedInt} setChecked={setCheckedInt} />
                </div>
                <div className="date">
                    <div className="text">
                        <span>Data Prioridade</span>
                    </div>
                    <div className="input">
                        <FaCalendarDays />
                        <ReactInputMask
                            mask="99/99/9999"
                            maskChar='_'
                            placeholder="Cafs Ignoradas"
                            value={dataPriority}
                            onChange={handleDataPriorityChange}
                        />
                    </div>
                </div>
                <div className="inffoSelect">
                    <span>AWB | Rota | ID da caf |  Descrição do status</span>
                </div>
            </div>
            <div className="upload">
                <UploadFile setProcessedData={setProcessedData} />
            </div>
        </header>
    )
}