import "./styles.scss";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const handleNavigationPartsReport = () => {
    navigate('/ReportParts');
  };
  const handleNavigationFinalRelationship = () => {
    navigate('/FinalRelationship');
  };
  const handleNavigationPriorityParts = () => {
    navigate('/PriorityParts');
  };
  const handleNavigationRelationship = () => {
    navigate('/Relationship');
  };
  
  return (
    <main className="mainHome">
      <button onClick={handleNavigationPartsReport}>Relatório de Peças</button>
      <button onClick={handleNavigationFinalRelationship}>Relatório Final</button>
      <button onClick={handleNavigationPriorityParts}>Relatório Prioridade</button>
      <button onClick={handleNavigationRelationship}>Relatório</button>
    </main>
  )
}

export default Home