import { useContext, useEffect, useState } from "react";
import { PriorityPartsContext } from "../../../context/Context";
import ReactInputMask from "react-input-mask";
import { FaWindowClose } from "react-icons/fa";

export default function EditBox({ click, setClick, editItem }) {
    const { objData, selectedKey, setObjData } = useContext(PriorityPartsContext);
    const [objLoc, setObjLoc] = useState([]);
    const [name, setName] = useState("");
    const [selectedDescription, setSelectedDescription] = useState("");
    const [cafList, setCafList] = useState([]); // Para armazenar a lista de cafs

    useEffect(() => {
        function handleClickOutside(event) {
            if (click) {
                if (!event.target.closest(`.itemEdit`)) {
                    editItem();
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [editItem, click]);

    // Obtém o item selecionado baseado no router (ID principal agora é o router)
    const itenSelected = objData[selectedKey];

    useEffect(() => {
        if (!itenSelected) return;

        setObjLoc(objData)
        setName(itenSelected.router); // Usando router como nome
        setSelectedDescription(itenSelected.description);
        setCafList(Object.values(itenSelected.cafs)); // Carrega a lista de cafs
    }, [itenSelected, selectedKey, objData]);

    const handleDescriptionChange = (cafID, newDescription) => {
        // Atualiza a descrição do CAF específico dentro do router
        const updatedCafs = { ...itenSelected.cafs };
        updatedCafs[cafID].cafsDescription = newDescription; // Atualiza a descrição

        // Atualiza o objData no contexto com a nova descrição do CAF
        const updatedData = {
            ...objData,
            [selectedKey]: {
                ...objData[selectedKey],
                cafs: updatedCafs, // Atualiza os cafs com a nova descrição
            },
        };

        setObjLoc(updatedData); // Atualiza no contexto
    };

    const handleAnnotationChange = (cafID, newAnnotation) => {
        // Atualiza a anotação do CAF específico
        const updatedCafs = { ...itenSelected.cafs };
        updatedCafs[cafID].annotation = newAnnotation;

        // Atualiza o objData no contexto
        const updatedData = {
            ...objData,
            [selectedKey]: {
                ...objData[selectedKey],
                cafs: updatedCafs,
            },
        };

        setObjLoc(updatedData);
    };

    const handleDeleteCaf = (cafsID) => {

        let total = 0;
        let totalInCAF = 0;
        let totalOfCAF = 0;

        // Usando for...in para percorrer objLoc e acessar cada router
        for (let key in objLoc) {
            const value = objLoc[key];

            if (value.router === selectedKey) {
                // Atualizando os totais

                total = value.total - value.cafs[cafsID]?.cafsTotal;
                totalInCAF = value.totalInCAF - value.cafs[cafsID]?.cafsTotal;
                totalOfCAF = value.totalOfCAF - 1;

                // Deletando o item de cafs usando o cafsID
                delete objData[selectedKey].cafs[cafsID]
            }
        }

        // Atualizando o objData com os novos valores
        const updatedData = {
            ...objData,
            [selectedKey]: {
                ...objData[selectedKey],
                total: total,
                totalInCAF: totalInCAF,
                totalOfCAF: totalOfCAF
            },
        };

        // Atualizando o estado
        setObjLoc(updatedData);
    };



    const clickSave = () => {
        if (!itenSelected) return;


        const filterDefault = Object.fromEntries(
            Object.entries(objLoc).filter(([key, value]) => value.total !== 0)
        );


        setObjData(filterDefault); // Atualiza no contexto
        editItem(); // Fecha o modal após salvar
    };

    const descriptionMap = {
        inCAF: "Em CAF",
        justLeft: "Na Rua",
        inStreet: "Entregando",
        default: "Indefinido",
    };

    if (!selectedKey || !itenSelected) return null;

    return (
        <div className={`editBox editBox-${click}`}>
            <div className="itemEdit">
                <div className="icon" onClick={editItem}>
                    <FaWindowClose />
                </div>
                <div className="title">
                    <span>{itenSelected?.router}</span>
                </div>
                <div className="cafList">
                    {Object.entries(itenSelected?.cafs || {}).map(([cafID, caf]) => (
                        <div key={cafID} className="cafItem">
                            <div className="cafInfo">
                                <span>{`CAF ID: ${caf.cafsID}`}</span>
                                <span>{`Total: ${caf.cafsTotal}`}</span>
                            </div>

                            <div className="dataLoading">
                                <select
                                    onChange={(e) => handleDescriptionChange(caf.cafsID, e.target.value)} // Passa o cafID e o novo valor da descrição
                                    value={caf.cafsDescription || "default"} // Usa "default" caso não tenha descrição
                                >
                                    <option value={caf.cafsDescription || "default"}>
                                        {descriptionMap[caf.cafsDescription] || "Indefinido"}
                                    </option>
                                    {Object.entries(descriptionMap)
                                        .filter(([key]) => key !== caf.cafsDescription) // Exclui a descrição atual
                                        .map(([key, value]) => (
                                            <option key={key} value={key}>
                                                {value}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            <div className="annotation">
                                <div className="observation">
                                    <ReactInputMask
                                        placeholder="Observação"
                                        value={caf.annotation || ""}
                                        onChange={(e) => handleAnnotationChange(caf.cafsID, e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Botão para deletar o CAF */}
                            <div className="deleteCaf">
                                <button onClick={() => handleDeleteCaf(caf.cafsID)}>Excluir CAF</button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="buttonDn">
                    <button onClick={clickSave}>SALVAR</button>
                </div>
            </div>
        </div>
    );
}
