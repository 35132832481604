import "./styles.scss";
import { useContext, useEffect, useState } from "react";
import Calendar from 'react-calendar';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import PropTypes from 'prop-types';
import { RelationshipContext } from "../../../context/Context";




function CustomCalendar({savedDays}) {
    const { calendar, setCalendar, activeStartDate, setActiveStartDate } = useContext(RelationshipContext);   

    // Update Active Start Date when the selected day changes
    useEffect(() => {
        // Update Active Start Date for the month of selected day
        setActiveStartDate(new Date(calendar.getFullYear(), calendar.getMonth(), 1));
    }, [calendar, setActiveStartDate]);

    // Removes unwanted inline styles from React-Calendar
    useEffect(() => {
        const removeInlineStyles = () => {
            const calendarElements = document.querySelectorAll('.react-calendar *');
            calendarElements.forEach(element => {
                element.style = null;
            });
        };
    
        removeInlineStyles();
    }, []);

    const isSameDate = (date1, date2) => (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    );

    return (
        <div className="calendarBox">
            <Calendar
                onChange={(date) => {
                    setCalendar(date);
                    // Reset Active Start Date when a new date is selected
                    setActiveStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
                }}
                value={calendar}
                prevLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                prev2Label={null}
                next2Label={null}
                
                onActiveStartDateChange={({ activeStartDate }) => {
                    // Always updates ActiveTartDate when the month changes
                    setActiveStartDate(activeStartDate);
                }}
                formatShortWeekday={(locale, date) =>
                    ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'][date.getDay()]
                }
                showFixedNumberOfWeeks={true}
                tileClassName={({ date: tileDate, view }) => {
                    
                    const isSavedDay = savedDays.some(item =>
                        isSameDate(item, tileDate)
                    );

                    if (view !== 'month') return null;
                
                    const currentMonth = activeStartDate.getMonth();
                
                    if (isSameDate(tileDate, calendar)){
                        return 'selectedDay';
                    }
                
                    if (isSavedDay) return 'daytime';
                
                    if (tileDate.getMonth() !== currentMonth) return 'calendar__otherMonth';
                
                    return null;
                }}
            />
        </div>
    );
}

CustomCalendar.propTypes = {
    savedDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};

CustomCalendar.defaultProps = {
    savedDays: [
        new Date(1000, 1, 1),
    ],
};

export default CustomCalendar;
