import { useContext } from "react";
import { RelationshipContext } from "../../../context/Context";
import { MdDataSaverOn } from "react-icons/md";

export default function ToAdd() {
    const { openConfig, setOpenConfig, inputData, setInputData } = useContext(RelationshipContext);

    const labels = {
        cer: "CER",
        sao: "SÃO",
        inj: "INJ",
        loc: "LOC",
        card: "CARD'S",
        amountCAF: "Peças em CAF",
        doneCAF: "CAF’s Feitas",
        issuedCAF: "CAF’s Expedidas",
    };

    const handleOptions = () => {
        setOpenConfig(prev => !prev);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Se o valor for vazio, substitua por 0
        const newValue = value === "" ? 0 : value;

        setInputData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
    };

    const handleFocus = (e) => {
        // Resetar o campo para vazio quando o input for focado
        e.target.value = "";
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpenConfig(prev => !prev);
    };

    return (
        <div className={`ToAddBG ${openConfig ? 'config-open' : 'config-closed'}`}>
            <div className="toAdd" onClick={() => handleOptions()}>
                <MdDataSaverOn />
            </div>
            <div className="inputs">
                <form onSubmit={handleSubmit}>
                    <div className="itens">
                        {Object.keys(inputData).map((key) => (
                            <div key={key}>
                                <span htmlFor={key}>{labels[key] || key.toUpperCase()}</span>
                                <input
                                    type="number"
                                    id={key}
                                    name={key}
                                    value={inputData[key]}
                                    onChange={handleChange}
                                    onFocus={handleFocus} // Resetar o campo ao focar
                                />
                            </div>
                        ))}
                    </div>
                    <button type="submit">Atualizar</button>
                </form>
            </div>
        </div>
    );
}
