import { useContext, useEffect, useState } from "react";
import { PriorityPartsContext } from "../../../context/Context";


const getRouter = (data) => {
    return data.map((obj) => {
        const router = obj.Rota.split('-')

        if (router[1] === 'FOR') {
            const updatedRota = router[4].replace(/[\[\]]/g, '');
            return {
                ...obj,
                Rota: updatedRota
            };
        }
    });

}

const categorizeDescription = (descriptions) => {
    if (typeof descriptions !== 'string') return "default"; // Verificar se 'descriptions' é uma string

    const atTheBase = ["DESCARREGADO", "COLETA RECEBIDA", "TRANSFERENCIA PARA", "EMBARQUE RECEBIDO", "RETIRADO DA CAF ANTERIOR", "RECEBIDO CD DE"];
    const inCAF = ["EM ROTA"];
    const justLeft = ["PROCESSO DE ENTREGA", "EM TRANSFERÊNCIA SECUNDÁRIA"];
    const inStreet = [
        "CLIENTE AUSENTE/ESTABELECIMENTO FECHADO", "DESTINATARIO DESCONHECIDO", "ENDERECO DESTINATARIO NAO LOCALIZADO", "ENTREGA REALIZADA",
        "ESPERA SUPERIOR A 20 MINUTOS", "FALTA NUMERO APT/CASA", "FORA DE ROTA", "VEICULO ENTREGADOR AVARIADO", "DUAS OU MAIS VEZES AUSENTE"
    ];
    const inNC = ["EMBALAGEM EM ANALISE", "DEVOLUÇÃO POR NC"];

    if (atTheBase.some(item => descriptions.startsWith(item))) {
        return "atTheBase";
    } else if (inCAF.some(item => descriptions.startsWith(item))) {
        return "inCAF";
    } else if (justLeft.some(item => descriptions.startsWith(item))) {
        return "justLeft";
    } else if (inStreet.some(item => descriptions.startsWith(item))) {
        return "inStreet";
    } else if (inNC.some(item => descriptions.startsWith(item))) {
        return "inNC";
    } else {
        return "default";
    }
};

const updateDescriptions = (dados) => {
    return dados.map(item => {
        const categoria = categorizeDescription(item.Descrição);
        return {
            ...item,
            Descrição: categoria  // Alterando a descrição para o nome da variável
        };
    });
};

function createObjects(dados) {
    return dados.reduce((acc, item) => {
        const { Rota, ID, AWB, Descrição } = item;

        // Inicializa a rota se não existir
        if (!acc[Rota]) {
            acc[Rota] = {
                router: Rota,
                total: 0,
                totallyOutOf: 0,
                totalInCAF: 0,
                totalOfCAF: 0,
                cafs: {},
            };
        }

        acc[Rota].total += 1;

        // Verifica se o ID é 0 ou vazio
        if (ID === "0" || !ID) {
            acc[Rota].totallyOutOf += 1;
        } else {
            acc[Rota].totalOfCAF = Object.keys(acc[Rota].cafs).length;
            acc[Rota].totalInCAF += 1;

            // Inicializa o ID dentro de 'cafs' se não existir
            if (!acc[Rota].cafs[ID]) {
                acc[Rota].cafs[ID] = {
                    cafsID: ID,
                    cafsTotal: 0,
                    cafsDescription: "",
                    annotation: "",
                    AWB: [],
                    showAWB: false,
                };
            }

            // Adiciona AWB ao ID correspondente
            if (!acc[Rota].cafs[ID].AWB.includes(AWB)) {
                acc[Rota].cafs[ID].AWB.push(AWB);
            }

            // Incrementa o total de AWBs para o ID
            acc[Rota].cafs[ID].cafsTotal += 1;

            // Atualiza a descrição mais frequente
            if (!acc[Rota].cafs[ID].cafsDescription) {
                acc[Rota].cafs[ID].cafsDescription = Descrição;
            } else {
                const currentDescription = acc[Rota].cafs[ID].cafsDescription;
                const descriptions = dados
                    .filter((i) => i.Rota === Rota && i.ID === ID)
                    .map((i) => i.Descrição);

                const mostFrequentDescription = descriptions.sort(
                    (a, b) =>
                        descriptions.filter((desc) => desc === b).length -
                        descriptions.filter((desc) => desc === a).length
                )[0];

                acc[Rota].cafs[ID].cafsDescription = mostFrequentDescription || currentDescription;
            }
        }

        return acc;
    }, {});
}


function sortObjectByAlphabeticalOrdering(data) {
    if (!data || Object.keys(data).length === 0) return {}; // Verifica se o objeto está vazio

    const regexZeroNumLetter = /^0(\d+[A-Z])$/; // 01A, 06B
    const regexNumLetterNum = /^(\d+[A-Z])(\d+)$/; // 1B1, 8C1
    const regexTwoLettersNum = /^([A-Z]{2}\d+)$/; // CA1, MA1
    const regexLetterTwoNums = /^([A-Z]\d{2})$/; // V01, S10

    const getNormalizedValue = (router) => {
        if (regexZeroNumLetter.test(router)) {
            return router.replace(/^0/, ''); // Remove leading 0
        } else if (regexNumLetterNum.test(router)) {
            return router.replace(/\d+$/, ''); // Remove last digit for comparison
        } else if (regexTwoLettersNum.test(router)) {
            return router; // No change needed
        } else if (regexLetterTwoNums.test(router)) {
            return router; // No change needed
        }
        return router; // Default case
    };

    const getLastNumber = (router) => {
        const match = router.match(/\d+$/); // Extract last number
        return match ? parseInt(match[0], 10) : 0;
    };

    function compareRouter(keyA, keyB) {
        const normA = getNormalizedValue(keyA);
        const normB = getNormalizedValue(keyB);

        if (regexTwoLettersNum.test(keyA) && regexTwoLettersNum.test(keyB)) {
            // Compare two letters followed by a number
            return keyA.localeCompare(keyB);
        } else if (regexLetterTwoNums.test(keyA) && regexLetterTwoNums.test(keyB)) {
            // Compare letter followed by two numbers
            return keyA.localeCompare(keyB);
        } else if (regexNumLetterNum.test(keyA) && regexNumLetterNum.test(keyB)) {
            // Compare based on normalized values and then by the last number
            const normalizedComparison = normA.localeCompare(normB);
            if (normalizedComparison === 0) {
                return getLastNumber(keyA) - getLastNumber(keyB);
            }
            return normalizedComparison;
        } else {
            // Normalize and compare the routers based on their normalized values
            return normA.localeCompare(normB);
        }
    }

    // Converte o objeto em uma lista de pares chave-valor
    const entries = Object.entries(data);

    // Ordena os pares com base nas chaves usando compareRouter
    entries.sort(([keyA], [keyB]) => compareRouter(keyA, keyB));

    // Reconstrói o objeto ordenado
    return Object.fromEntries(entries);
}

function filterItemInt(data) {
    const intList = ['S', 'V', 'W', 'Q', 'L'];

    const regex = new RegExp(`^(${intList.join('|')})`);
    return Object.fromEntries(
        Object.entries(data).filter(([key]) => !regex.test(key))
    );
}
function filterItemMetropolitan(data) {
    const metropolitanList = ['MR', 'PC', 'EU', 'HO', 'AQ', 'IT', 'MA', 'CA'];
    const regex = new RegExp(`^(${metropolitanList.join('|')})`);
    return Object.fromEntries(
        Object.entries(data).filter(([key]) => !regex.test(key))
    );
}

function filterItemUnwanted(data) {
    const allowedDescriptions = ['inCAF'];

    return Object.fromEntries(
        Object.entries(data).map(([key, value]) => {

            const originalCafs = value.cafs || {};
            let total = value.total;
            let totalInCAF = value.totalInCAF;
            let totalOfCAF = value.totalOfCAF;

            // Filtrar os CAFs permitidos e ajustar os totais
            const filteredCafs = Object.fromEntries(
                Object.entries(originalCafs).filter(([_, caf]) => {
                    if (!allowedDescriptions.includes(caf.cafsDescription)) {
                        total -= caf.cafsTotal
                        totalInCAF -= caf.cafsTotal;
                        totalOfCAF -= 1;
                        return false; // Remove o CAF
                    }
                    return true; // Mantém o CAF
                })
            );

            // Garantir que os totais não sejam negativos
            total = Math.max(0, total);
            totalInCAF = Math.max(0, totalInCAF);
            totalOfCAF = Math.max(0, totalOfCAF);


            return [
                key,
                {
                    ...value,
                    cafs: filteredCafs,
                    total,
                    totalInCAF,
                    totalOfCAF,
                }
            ];
        })
    );
}

function filterItemDefaults(data) {
    return Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value.total !== 0)
    );
}



export default function CreateList() {
    const { processedData, objData, setObjData, checkedInt, checkedMetropolitan, checkedUnwanted } = useContext(PriorityPartsContext);
    const [expandedCafs, setExpandedCafs] = useState({}); // Estado para controlar a expansão dos CAFs

    useEffect(() => {
        if (!processedData || processedData.length === 0) return;

        const processedDataDefault = processedData[0].data;
        const router = getRouter(processedDataDefault);
        const descriptions = updateDescriptions(router);
        const createObjs = createObjects(descriptions);
        const alphabeticalOrdering = sortObjectByAlphabeticalOrdering(createObjs);

        const RouterInt = checkedInt ? alphabeticalOrdering : filterItemInt(alphabeticalOrdering);
        const RouterMetropolitan = checkedMetropolitan ? RouterInt : filterItemMetropolitan(RouterInt);
        const RouterUnwanted = checkedUnwanted ? RouterMetropolitan : filterItemUnwanted(RouterMetropolitan);

        const RemoveDefaults = filterItemDefaults(RouterUnwanted)
        
        setObjData(RemoveDefaults);
    }, [processedData, setObjData, checkedInt, checkedMetropolitan, checkedUnwanted]);

    // Transformar objData em um array e dividir em duas partes
    const entries = Object.entries(objData);
    const midIndex = Math.ceil(entries.length / 2);
    const firstHalf = entries.slice(0, midIndex);
    const secondHalf = entries.slice(midIndex);

    const descriptionMap = {
        inCAF: "Em CAF",
        justLeft: "Na Rua",
        inStreet: "Entregando",
        default: "Indefinido",
    };

    // Função para alternar a expansão do CAF
    const toggleCafExpansion = (cafsID) => {
        setExpandedCafs((prevState) => ({
            ...prevState,
            [cafsID]: !prevState[cafsID],
        }));
    };

    const renderPart = (data) => (
        data.map(([key, routerData]) => (
            <div className="item" key={key}>
                <div className="headerItem">
                    <div className="partItem">
                        <span>BASE</span>
                        <span>{routerData.totallyOutOf}</span>
                    </div>
                    <div className="partItem">
                        <span>CAF'S FEITAS</span>
                        <span>{routerData.totalOfCAF}</span>
                    </div>
                    <div className="paartRouter">
                        <span>{routerData.router}</span>
                    </div>
                    <div className="partItem">
                        <span>EM CAF</span>
                        <span>{routerData.totalInCAF}</span>
                    </div>
                    <div className="partItem">
                        <span>TOTAL</span>
                        <span>{routerData.total}</span>
                    </div>
                </div>
                {Object.keys(routerData.cafs).length > 0 && (
                    <div className="mainItem">
                        <div className="titles">
                            <div>
                                <span>CAF</span>
                            </div>
                            <div>
                                <span>PÇS</span>
                            </div>
                            <div>
                                <span>STATUS</span>
                            </div>
                            <div>
                                <span>OBS</span>
                            </div>
                        </div>
                        {Object.entries(routerData.cafs).map(([cafsID, cafData]) => (
                            <div className={`caf caf-${cafsID}`} key={cafsID} onClick={() => toggleCafExpansion(cafsID)} >
                                <div className="itens">
                                    <div>
                                        <span>{cafData.cafsID}</span>
                                    </div>
                                    <div>
                                        <span>{cafData.cafsTotal}</span>
                                    </div>
                                    <div>
                                        <span>{descriptionMap[cafData.cafsDescription] || "Indefinido"}</span>
                                    </div>
                                    <div>
                                        <div className="obs">
                                            <span>{cafData.annotation || "\u200B"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="awbs">
                                    {expandedCafs[cafsID] && (
                                        <div className="awbList">
                                            {cafData.AWB && cafData.AWB.map((awb, index) => (
                                                <div key={index} className="awbItem">
                                                    <span>{awb}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ))
    );

    return (
        <div className="mainSheet">
            <div className="parts">
                {renderPart(firstHalf)}
            </div>
            <div className="parts">
                {renderPart(secondHalf)}
            </div>
        </div>
    );
}