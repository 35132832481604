import React from 'react';


function MonthlyData({ data }) {
    return (
        <div className="monthlyData">
            {data.map(({ icon, label, value }) => (
                <div className="boxValue" key={label}>
                    <div className="bc1">
                        {icon}
                        <span className="name">{label}</span>
                    </div>
                    <div className="bc2">
                        <span>{value}</span>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MonthlyData;