import { FaCreditCard } from "react-icons/fa";
import { GiTruck } from "react-icons/gi";
import { ImTruck } from "react-icons/im";
import { LiaTruckLoadingSolid } from "react-icons/lia";
import { PiTruckTrailerFill } from "react-icons/pi";

function Itens({ itens }) {

    const { card, cer, sao, inj, loc } = itens;

    const numericItens = Object.fromEntries(
        Object.entries(itens).map(([key, value]) => [key, Number(value)])
    );
    
    // Filtrar apenas valores maiores que 0
    const filteredValues = Object.values(numericItens).filter(val => val > 0);
    
    // Calcular o total
    const total = filteredValues.reduce((acc, val) => acc + val, 0);
    
    // Calcular as porcentagens
    const percentValues = {
        card: numericItens.card > 0 ? ((numericItens.card / total) * 100).toFixed(0) : 0,
        cer: numericItens.cer > 0 ? ((numericItens.cer / total) * 100).toFixed(0) : 0,
        sao: numericItens.sao > 0 ? ((numericItens.sao / total) * 100).toFixed(0) : 0,
        inj: numericItens.inj > 0 ? ((numericItens.inj / total) * 100).toFixed(0) : 0,
        loc: numericItens.loc > 0 ? ((numericItens.loc / total) * 100).toFixed(0) : 0,
    };
    
    const max = Math.max(...filteredValues);

    const percentValuesBar = {
        card: card > 0 ? ((card / max) * 100).toFixed(0) : 0,
        cer: cer > 0 ? ((cer / max) * 100).toFixed(0) : 0,
        sao: sao > 0 ? ((sao / max) * 100).toFixed(0) : 0,
        inj: inj > 0 ? ((inj / max) * 100).toFixed(0) : 0,
        loc: loc > 0 ? ((loc / max) * 100).toFixed(0) : 0,
    };


    const data1 = [
        { icon: <FaCreditCard />, label: 'CARD', value: card, percentValues: percentValues.card, bar: percentValuesBar.card },
        { icon: <GiTruck />, label: 'CER', value: cer, percentValues: percentValues.cer, bar: percentValuesBar.cer },
        { icon: <PiTruckTrailerFill />, label: 'SAO', value: sao, percentValues: percentValues.sao, bar: percentValuesBar.sao },
    ];
    const data2 = [
        { icon: <ImTruck />, label: 'INJ', value: inj, percentValues: percentValues.inj, bar: percentValuesBar.inj },
        { icon: <LiaTruckLoadingSolid />, label: 'LOC', value: loc, percentValues: percentValues.loc, bar: percentValuesBar.loc }
    ];


    return (
        <>
            <div className="box">
                {data1.map(({ icon, label, value, percentValues, bar }) => (
                    <div className="item" key={label}>
                        <div className="bc">
                            <div className="icon">
                                {icon}
                            </div>
                            <div className="text">
                                <span>{label}</span>
                            </div>
                        </div>
                        <div className="description">
                            <div className="value">
                                <span>{value}</span>
                            </div>
                            <div className="bar">
                                <div className="barTop">
                                    <div style={{ width: `${bar}%` }}></div>
                                </div>
                                <div className="porc">
                                    <span>{percentValues}%</span>
                                </div>
                                <div className="barBot">
                                    <div style={{ width: `${bar}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="box">
                {data2.map(({ icon, label, value, percentValues, bar }) => (
                    <div className="item" key={label}>
                        <div className="bc">
                            <div className="icon">
                                {icon}
                            </div>
                            <div className="text">
                                <span >{label}</span>
                            </div>
                        </div>
                        <div className="description">
                            <div className="value">
                                <span>{value}</span>
                            </div>
                            <div className="bar">
                                <div className="barTop">
                                    <div style={{ width: `${bar}%` }}></div>
                                </div>
                                <div className="porc">
                                    <span>{percentValues}%</span>
                                </div>
                                <div className="barBot">
                                    <div style={{ width: `${bar}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Itens