import { useContext, useEffect, useState } from "react";
import { PriorityPartsContext } from "../../../context/Context";

function calculateTotals(data) {
    return Object.entries(data).reduce(
        (acc, [, value]) => {
            acc.inCaf += value.totalInCAF;
            acc.ofCaf += value.totallyOutOf;
            acc.totalCaf += value.totalOfCAF;
            acc.totalInCaf += value.total;
            return acc;
        },
        { inCaf: 0, ofCaf: 0, totalCaf: 0, totalInCaf: 0 }
    );
}

export default function Footer() {
    const { objData } = useContext(PriorityPartsContext);
    const [totals, setTotals] = useState({
        inCaf: 0,
        ofCaf: 0,
        totalCaf: 0,
        totalInCaf: 0
    });

    useEffect(() => {
        const newTotals = calculateTotals(objData);
        setTotals(newTotals);
    }, [objData]);

    return (
        <div className="footerSheet">
            <div>
                <span>Fora de CAF</span>
                <span>{totals.ofCaf}</span>
            </div>
            <div>
                <span>Em CAF</span>
                <span>{totals.inCaf}</span>
            </div>
            <div>
                <span>Total de CAF'S</span>
                <span>{totals.totalCaf}</span>
            </div>
            <div>
                <span>Total de Peças</span>
                <span>{totals.totalInCaf}</span>
            </div>
        </div>
    );
}
