import { useState } from "react";
import "./styles.scss";
import ReactInputMask from "react-input-mask";
import api from "../../../utils/api"; // Ajuste o caminho conforme sua estrutura

function Home() {
    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Limpa o erro quando o usuário começa a digitar novamente
        setError('');
    };

    const registerUser = async (event) => {
        event.preventDefault(); // Previne o comportamento padrão do formulário
        
        // Validação básica
        if (!formData.name || !formData.email) {
            setError('Por favor, preencha todos os campos');
            return;
        }

        try {
            setLoading(true);
            setError('');
            
            const response = await api.post('/users', formData); // Ajuste a rota conforme seu backend
            
            console.log('Usuário registrado com sucesso:', response.data);
            
            // Limpa o formulário após sucesso
            setFormData({
                name: '',
                email: ''
            });
            
            // Você pode adicionar uma mensagem de sucesso aqui se quiser
            alert('Usuário registrado com sucesso!');
            
        } catch (err) {
            setError(
                err.response?.data?.message || 
                'Ocorreu um erro ao registrar o usuário.'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="user">
            {error && <p className="error-message">{error}</p>}
            
            <ReactInputMask
                placeholder="Nome"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                disabled={loading}
            />
            <ReactInputMask
                placeholder="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                disabled={loading}
            />

            <button 
                onClick={registerUser} 
                disabled={loading}
            >
                {loading ? 'Registrando...' : 'Registrar'}
            </button>
        </div>
    );
}

export default Home;