import { useContext, useEffect, useState } from "react";
import { IoAlertCircle } from "react-icons/io5";
import { FinalRelationshipContext } from "../../../context/Context";

export default function ItensList({ data, today }) {

    const { setSelectedItems } = useContext(FinalRelationshipContext);
    const [selectedItemsState, setSelectedItemsState] = useState([]);

    // Função para calcular as prioridades
    const calculatePriority = (Data) => {
        if (!Data || Data.length === 0) return { dataValue: "Sem Prioridade.", priorityData: false, alertClass: "" };
    
        // Transforma as datas em timestamps
        const timestamps = Data.map((date) => {
            const [day, month, year] = date.split("/"); // Formato esperado "dd/mm/yyyy"
            const newDate = new Date(year, month - 1, day);
            newDate.setHours(0, 0, 0, 0);
            return newDate.getTime();
        });
    
        const counts = timestamps.reduce(
            (acc, timestamp) => {
                if (timestamp === today) acc.equal++; // Apenas conta as datas iguais a hoje
                return acc;
            },
            { equal: 0 }
        );
    
        let dataValue = "Sem Prioridade.";
        let priorityData = false;
    
        if (counts.equal > 0) {
            priorityData = true;
            dataValue = `${counts.equal} prioridade${counts.equal > 1 ? "s" : ""}`;
        }
    
        return { dataValue, priorityData };
    };
    

    const descriptionMap = {
        atTheBase: "Em base",
        inCAF: "Em CAF",
        justLeft: "Processo de Entrega",
        inStreet: "Entregando",
        inNC: "Na NC",
        default: "Indefinido"
    };

    const handleRightClick = (e, itemValue) => {
        e.preventDefault(); // Prevent default context menu
        // Toggle selection
        setSelectedItemsState((prevSelectedItems) => {
            const isSelected = prevSelectedItems.includes(itemValue);
            const updatedSelection = isSelected
                ? prevSelectedItems.filter((item) => item !== itemValue) // Remove from selection
                : [...prevSelectedItems, itemValue]; // Add to selection

            setSelectedItems(updatedSelection); // Update context with the selected items
            return updatedSelection;
        });
    };

    const isItemSelected = (itemValue) => selectedItemsState.includes(itemValue); // Check if the item is selected


    return data.map((item, index) => {
        const { Nome, AWB, Rota, Data, ID, description } = item;

        const { dataValue, priorityData } = calculatePriority(Data);

        const isOdd = index % 2 === 0; // Verifica se é um índice ímpar ou par

        const value = ID;

        return (
            <div
                className={isOdd
                    ? `added dc-${description} ${isItemSelected(value) ? 'selected' : ''}`
                    : `added addedBG dc-${description} ${isItemSelected(value) ? 'selected' : ''}`}
                key={item.ID}
                onContextMenu={(e) => handleRightClick(e, value)}
            >
                <div className="bcRouter">
                    <span className="itenSelected">✔️</span>
                    <span>{Rota}</span>
                </div>
                <div className="bcAggregate">
                    <span>{Nome}</span>
                </div>
                <div className="bcCAF">
                    <span>{ID}</span>
                </div>
                <div className="bcAmount">
                    <span>{AWB ? AWB.length : 0}</span>
                </div>
                <div className="bcState">
                    <span>{descriptionMap[description] || description}</span>
                </div>
                <div className="bcSituation">
                    <span>
                        {priorityData ? (
                            <>
                                <IoAlertCircle className="red" />
                                {dataValue}
                            </>
                        ) : (
                            dataValue
                        )}
                    </span>
                </div>
            </div>
        );
    });
}
