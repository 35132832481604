import { useEffect, useState, useRef } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Circle({ percentage }) {
    const [value, setValue] = useState(0);
    const prevPercentage = useRef(percentage); // Ref para armazenar o valor anterior de percentage

    useEffect(() => {
        // Limita o percentage para não ultrapassar 100%
        const validPercentage = Math.min(percentage, 100);

        // Verifique se percentage é um número válido
        if (isNaN(validPercentage) || validPercentage < 0) {
            console.error('Percentage inválido:', validPercentage);
            return; // Se o valor for inválido, não faça nada
        }

        // Calcula a diferença com o valor anterior
        const startValue = prevPercentage.current;
        const difference = Math.abs(validPercentage - startValue);

        const duration = difference === 100 ? 1000 : (difference / 100) * 1000;
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const newValue = startValue + progress * (validPercentage - startValue);
            setValue(newValue);

            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);

        // Atualiza o valor anterior de percentage após a animação
        prevPercentage.current = validPercentage;

        return () => cancelAnimationFrame(animate); // Clean up
    }, [percentage]);

    return (
        <div className="circle">
            <CircularProgressbar
                value={value} 
                styles={buildStyles({
                    textSize: '24px',
                    pathTransitionDuration: 0.1, 
                    pathColor: '#4995EB',
                    textColor: '#212529',
                    trailColor: 'rgba(214,214,214,.3)',
                    backgroundColor: '#f3f3f3',
                })}
                strokeWidth={13} 
            />
            <div className="texts">
                <span className="label">{Math.round(value)}<span className="smaller">%</span></span>
                <span className="use">Aproveitamento</span>
            </div>
        </div>
    );
}

export default Circle;
