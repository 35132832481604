import './styles.scss';
import { useContext, useEffect, useState } from 'react';
import { PriorityPartsContext } from '../../../context/Context';
import { captureDiv } from './CaptureDiv.js';
import SelectDriver from './SelectDriver';
import EditBox from './EditBox';
import CreateList from './CreateList.js';
import Footer from './Footer.js';

export default function Main(){

    const {objData, setObjData, selectedKey, selectedItems, setSelectedItems, dataPriority} = useContext(PriorityPartsContext)
    const [click, setClick] = useState(false);

    function editItem(){      
        click ? setClick(false) : setClick(true)
    }


    function removeItem() {
        if (selectedKey !== null && selectedKey !== "" && selectedKey !== undefined) {
            // Cria uma cópia de objData e remove o item com a chave selectedKey
            const { [selectedKey]: removedItem, ...filteredData } = objData;
            setObjData(filteredData); // Atualiza o objData sem o item removido
        } else {
            // Se selectedKey não estiver definido, remove os itens com base em selectedItems
            const filteredData = { ...objData }; // Cria uma cópia do objData
            selectedItems.forEach(itemID => {
                delete filteredData[itemID]; // Remove cada item que está em selectedItems
            });
            setObjData(filteredData); // Atualiza o objData com os itens removidos
            setSelectedItems([]); // Limpa a seleção
        }
    }
    
    const formattedDate = dataPriority.split('/').slice(0, 2).join('/');

    return(
        <main id="MainPP">
            <div className="edit">
                <SelectDriver />
                <div className="button">
                    <button type="submit" onClick={editItem} disabled={selectedKey !== null && selectedKey !== "" && selectedKey !== undefined ? false : true}>Editar</button>
                    <button type="submit" onClick={removeItem}>Apagar</button>
                </div>
            </div>
            <EditBox click={click} setClick={setClick} editItem={editItem}/>
            <div className="message">
                
            </div>

            <div className="graphic">
                <div className="buttonDn">
                    <button onClick={captureDiv}>Baixar</button>
                </div>
                <div className="sheet captureDiv">
                    <div className="herderSheet">
                        <div className="logo">
                            <div className="bgImage">

                            </div>
                        </div>
                        <div className="title">
                            <span>PRIORIDADE {formattedDate}</span>
                        </div>
                        <div className="logoProperty">
                        </div>
                    </div>
                    <CreateList />
                    <Footer />
                </div>
            </div>

        </main>
    )
}