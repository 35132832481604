import "./styles.scss";

import { useContext, useEffect, useState } from "react";

import { RelationshipContext } from "../../../context/Context";

import { MdDataSaverOn } from "react-icons/md";
import { FaTruck } from "react-icons/fa";
import { FaTruckRampBox, FaBox, FaCarSide } from "react-icons/fa6";

import Circle from "../Circle";
import CustomCalendar from "../CustomCalendar";
import Itens from "../Itens";
import MonthlyData from "../MonthlyData";
import ToAdd from "../ToApp";



function Home() {
    const { inputData, setOpenConfig } = useContext(RelationshipContext);

    const [itens, setItens] = useState({
            card: 0,
            cer: 0,
            sao: 0,
            inj: 0,
            loc: 0,
        });

    const [parts, setParts] = useState([]);
    const [percentage, setPercentage] = useState(0);

    const [savedDays, setSavedDays] = useState();

    useEffect(() => {

        parseInt(inputData.loc)
        const receivedParts = parseInt(inputData.loc) + parseInt(inputData.cer) + parseInt(inputData.sao) + parseInt(inputData.inj)
        setParts({
            receivedParts: receivedParts,
            CAFParts: inputData.amountCAF,
            CAFSMade: inputData.doneCAF,
            CAFExpeded: inputData.issuedCAF,
        })
        setItens({
            card: inputData.card,
            cer: inputData.cer,
            sao: inputData.sao,
            inj: inputData.inj,
            loc: inputData.loc,
        })

        const percent = ((100 * inputData.amountCAF) / receivedParts).toFixed(1);

        setPercentage(parseFloat(percent));


    }, [inputData, setPercentage]);

    const dataItem = [
        { icon: <FaTruck />, label: 'Peças Recebidas', value: parts.receivedParts },
        { icon: <FaTruckRampBox />, label: 'Peças em CAF', value: parts.CAFParts },
        { icon: <FaBox />, label: 'CAF’s Feitas', value: parts.CAFSMade },
        { icon: <FaCarSide />, label: 'CAF’s Expedidas', value: parts.CAFExpeded }
    ];
    const handleOptions = () => {
        setOpenConfig(prev => !prev);
    };

    return (
        <main id="Relationship">
            <div className="toAdd" onClick={() => handleOptions()}>
                <MdDataSaverOn />
            </div>
            <ToAdd />
            <section className="Diary">
                <div className="pri">
                    <div className="head">
                        <div className="caledar">
                            <CustomCalendar savedDays={savedDays} />
                        </div>
                        <div className="title">
                            <span>Relatório Diario</span>
                        </div>
                    </div>
                    <div className="graphic">
                        <div className="data">
                            <MonthlyData data={dataItem} />
                        </div>
                        <div className="dataGp">
                            <Circle percentage={percentage} />
                            <div className="observation">
                                <div className="box"></div>
                                <span>Volumetria recebida e colocada em Caf</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sec">
                    <Itens itens={itens} />
                </div>
            </section>
        </main>
    )
}

export default Home